import { StrictMode } from 'react'
import { HashRouter } from 'react-router-dom'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import ReactDOM from 'react-dom'
import App from './App'
import { NetworkContextName } from './constants'
import getLibrary from './utils/getLibrary'
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from './theme'
import reportWebVitals from './reportWebVitals'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

ReactDOM.render(
  <StrictMode>
    <HashRouter>
      <FixedGlobalStyle />
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <ThemeProvider>
            <ThemedGlobalStyle />
            <App />
          </ThemeProvider>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </HashRouter>
  </StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
