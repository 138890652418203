import { useMemo } from 'react'
import { TransactionDetails } from '../contexts/AppContext'
import { useActiveWeb3React } from '../hooks'
import { useAppConext } from '../hooks/useAppConext'

export function useChaintransactions(): { [txHash: string]: TransactionDetails } {
  const { chainId } = useActiveWeb3React()
  const { transactions } = useAppConext()

  return useMemo(() => {
    return chainId ? transactions[chainId] ?? {} : {}
  }, [chainId, transactions])
}
