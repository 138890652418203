import { Token, TokenAmount } from '../tokens'
import { useEffect, useState } from 'react'

import { useTokenContract } from '../hooks/useContract'
import { useActiveWeb3React } from 'hooks'
import { BigintIsh } from '@uniswap/sdk'
import { useAppConext } from './useAppConext'

export function useTokenBalance(
  address?: string | null,
  token?: Token
): { balance: TokenAmount | undefined; isLoading: boolean } {
  const { library, account } = useActiveWeb3React()
  const { transactions } = useAppConext()
  const [tokenBalance, setTokenBalance] = useState<TokenAmount>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const contract = useTokenContract(token?.isNative ? undefined : token?.address, false)

  useEffect(() => {
    let cancelled = false
    const fetch = async () => {
      try {
        if (!account || !library || !token || !address) {
          return
        }

        let balance: BigintIsh

        if (token.isNative) {
          setIsLoading(true)

          balance = (await library.getBalance(account)).toBigInt()
        } else {
          if (!contract) {
            return
          }

          setIsLoading(true)

          balance = await contract['balanceOf'](address)
        }

        if (!cancelled && token && balance) {
          setIsLoading(false)
          setTokenBalance(new TokenAmount(token, balance.toString()))
        }
      } catch (error) {
        setIsLoading(false)
        console.error('Error trying to get token balance: ', error)
      }

      return
    }

    fetch()

    return (): void => {
      cancelled = true
    }
  }, [contract, address, token, library, account, transactions])

  return { balance: tokenBalance, isLoading }
}
