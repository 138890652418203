import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { NetworkConnector } from './NetworkConnector'
import {
  ChainId,
  ETHEREUM_MAINNET_RPC_URL,
  ETHEREUM_TESTNET_RPC_URL,
  POLYGON_MAINNET_RPC_URL,
  POLYGON_TESTNET_RPC_URL,
} from '../constants'
import INJECTED_ICON_URL from '../assets/images/arrow-right.svg'
import METAMASK_ICON_URL from '../assets/images/metamask.png'
import WALLETCONNECT_ICON_URL from '../assets/images/walletConnectIcon.svg'

if (typeof ETHEREUM_MAINNET_RPC_URL === 'undefined') {
  throw new Error(`REACT_APP_BINANCE_MAINNET_RPC_URL must be a defined environment variable`)
}

if (typeof POLYGON_MAINNET_RPC_URL === 'undefined') {
  throw new Error(`REACT_APP_POLYGON_MAINNET_RPC_URL must be a defined environment variable`)
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconURL: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

const POLLING_INTERVAL = 8000
const RPC_URLS: { [chainId: number]: string } = {
  [ChainId.MAINNET]: ETHEREUM_MAINNET_RPC_URL as string,
  [ChainId.RINKEBY]: ETHEREUM_TESTNET_RPC_URL as string,
  [ChainId.POLYGON_MAINNET]: POLYGON_MAINNET_RPC_URL as string,
  [ChainId.POLYGON_TESTNET]: POLYGON_TESTNET_RPC_URL as string,
}

export const injected = new InjectedConnector({
  supportedChainIds: [ChainId.MAINNET, ChainId.RINKEBY, ChainId.POLYGON_MAINNET, ChainId.POLYGON_TESTNET],
})

export const walletconnect = new WalletConnectConnector({
  rpc: RPC_URLS,
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
})

export const network = new NetworkConnector({
  defaultChainId: ChainId.MAINNET,
  urls: {
    [ChainId.MAINNET]: ETHEREUM_MAINNET_RPC_URL,
    [ChainId.POLYGON_MAINNET]: POLYGON_MAINNET_RPC_URL,
  },
})

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconURL: INJECTED_ICON_URL,
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconURL: METAMASK_ICON_URL,
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconURL: WALLETCONNECT_ICON_URL,
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
}
