import { Fraction } from '@uniswap/sdk'
import ethereumLogoUrl from 'assets/images/ethereum-logo.png'
import maticLogoUrl from 'assets/images/matic-logo.png'

export interface ChainData {
  readonly explorer: string
  readonly label: string
  readonly logoUrl?: string
  readonly rpcUrls?: string[]
  readonly nativeCurrency: {
    name: string
    symbol: string
    decimals: number
  }
}

export enum ChainId {
  MAINNET = 1,
  RINKEBY = 4,
  POLYGON_MAINNET = 137,
  POLYGON_TESTNET = 80001,
}

export type ChainInfo = { readonly [chainId in ChainId]: ChainData }

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
export const NODE_ENV = process.env.NODE_ENV || 'development'
export const ETHEREUM_MAINNET_RPC_URL = process.env.REACT_APP_ETHEREUM_MAINNET_RPC_URL
export const ETHEREUM_TESTNET_RPC_URL = process.env.REACT_APP_ETHEREUM_TESTNET_RPC_URL
export const POLYGON_MAINNET_RPC_URL = process.env.REACT_APP_POLYGON_MAINNET_RPC_URL
export const POLYGON_TESTNET_RPC_URL = process.env.REACT_APP_POLYGON_TESTNET_RPC_URL
export const ETHEREUM_CONTRACT = process.env.REACT_APP_ETHEREUM_CONTRACT
export const POLYGON_CONTRACT = process.env.REACT_APP_POLYGON_CONTRACT
export const NetworkContextName = 'NETWORK'
export const DEFAULT_DENOMINATOR = '1000000000000000000'
export const USER_LIMIT = new Fraction('35000000000000000000000', DEFAULT_DENOMINATOR)
export const TOTAL_LIMIT = new Fraction('3000000000000000000000000', DEFAULT_DENOMINATOR)
export const DENOMINATOR = new Fraction(DEFAULT_DENOMINATOR)
export const CONTRACT_LIST: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '',
  [ChainId.RINKEBY]: '',
  [ChainId.POLYGON_MAINNET]: '',
  [ChainId.POLYGON_TESTNET]: '',
}
export const CHAIN_INFO: ChainInfo = {
  [ChainId.MAINNET]: {
    explorer: 'https://etherscan.io/',
    label: 'Ethereum',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
  },
  [ChainId.RINKEBY]: {
    explorer: 'https://rinkeby.etherscan.io/',
    label: 'Rinkeby',
    nativeCurrency: { name: 'Rinkeby ETH', symbol: 'rinkETH', decimals: 18 },
  },
  [ChainId.POLYGON_MAINNET]: {
    explorer: 'https://polygonscan.com/',
    label: 'Matic',
    logoUrl: maticLogoUrl,
    nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
    rpcUrls: ['https://polygon-rpc.com'],
  },
  [ChainId.POLYGON_TESTNET]: {
    explorer: 'https://mumbai.polygonscan.com/',
    label: 'Mumbai',
    nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
  },
}
