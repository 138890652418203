import { useActiveWeb3React } from '.'
import { useAppConext } from './useAppConext'
import { TransactionDetails } from '../contexts/AppContext'

export function useAllTransactions(): { [txHash: string]: TransactionDetails } {
  const { chainId } = useActiveWeb3React()
  const { transactions } = useAppConext()

  return chainId ? transactions[chainId] ?? {} : {}
}
