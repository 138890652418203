import JSBI from 'jsbi'
import invariant from 'tiny-invariant'
import { Currency, CurrencyAmount, BigintIsh } from '@uniswap/sdk'
import { getAddress } from '@ethersproject/address'
import { ChainId, ZERO_ADDRESS } from '../constants'
import EthereumLogo from '../assets/images/ethereum-logo.png'
import MaticLogo from '../assets/images/matic-logo.png'

export class Token extends Currency {
  public readonly isNative: boolean = false
  public readonly isToken: boolean = true
  public readonly chainId: ChainId
  public readonly address: string
  public readonly nativeImage: string = ''

  public constructor(chainId: ChainId | number, address: string, decimals: number, symbol?: string, name?: string) {
    super(decimals, symbol, name)
    this.chainId = chainId
    try {
      this.address = getAddress(address)
    } catch (error) {
      throw new Error(`${address} is not a valid address.`)
    }
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: Token): boolean {
    // short circuit on reference equality
    if (this === other) {
      return true
    }
    return this.chainId === other.chainId && this.address === other.address
  }

  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  public sortsBefore(other: Token): boolean {
    invariant(this.chainId === other.chainId, 'CHAIN_IDS')
    invariant(this.address !== other.address, 'ADDRESSES')
    return this.address.toLowerCase() < other.address.toLowerCase()
  }
}

export class NativeCurrency extends Token {
  public readonly isNative: boolean = true
  public readonly isToken: boolean = false

  public constructor(chainId: ChainId | number, decimals: number, symbol?: string, name?: string) {
    super(chainId, ZERO_ADDRESS, decimals, symbol, name)
  }

  public equals(other: NativeCurrency): boolean {
    return other.isNative && other.chainId === this.chainId
  }
}

export class Ether extends NativeCurrency {
  public readonly nativeImage: string = EthereumLogo

  public constructor(chainId: ChainId | number) {
    super(chainId, 18, 'ETH', 'Ether')
  }
}

export class Matic extends NativeCurrency {
  public readonly nativeImage: string = MaticLogo

  public constructor(chainId: ChainId | number) {
    super(chainId, 18, 'MATIC', 'Matic')
  }
}

export class TokenAmount extends CurrencyAmount {
  public readonly token: Token

  // amount _must_ be raw, i.e. in the native representation
  public constructor(token: Token, amount: BigintIsh) {
    super(token, amount)
    this.token = token
  }

  public add(other: TokenAmount): TokenAmount {
    invariant(this.token.equals(other.token), 'TOKEN')
    return new TokenAmount(this.token, JSBI.add(this.raw, other.raw))
  }

  public subtract(other: TokenAmount): TokenAmount {
    invariant(this.token.equals(other.token), 'TOKEN')
    return new TokenAmount(this.token, JSBI.subtract(this.raw, other.raw))
  }
}

export function getNativeToken(chainId: ChainId | number): NativeCurrency {
  switch (chainId) {
    case ChainId.POLYGON_MAINNET:
    case ChainId.POLYGON_TESTNET: {
      return new Matic(chainId)
    }
    case ChainId.RINKEBY:
    case ChainId.MAINNET:
    default: {
      return new Ether(chainId)
    }
  }
}
