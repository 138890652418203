import { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import Header from './components/Header'
import Web3ReactManager from './components/Web3ReactManager'
import { AppProvider } from './contexts/AppContext'
import Phase1 from './Phase1'
import Rewards from './Rewards'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 100px;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 16px;
    padding-top: 2rem;
  `};

  z-index: 1;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

export default function App(): JSX.Element {
  return (
    <Suspense fallback={null}>
      <AppProvider>
        <Web3ReactManager>
          <AppWrapper>
            <HeaderWrapper>
              <Header />
            </HeaderWrapper>
            <BodyWrapper>
              <Switch>
                <Route exact strict path="/" component={Phase1} />
                <Route exact strict path="/rewards" component={Rewards} />
              </Switch>
              <Marginer />
            </BodyWrapper>
          </AppWrapper>
        </Web3ReactManager>
      </AppProvider>
    </Suspense>
  )
}
