import { TransactionResponse } from '@ethersproject/providers'
import { useCallback } from 'react'
import { useActiveWeb3React } from '.'
import { useAppConext } from './useAppConext'

export function useTransactionAdder(): (
  response: TransactionResponse,
  customData?: { summary?: string; approval?: { tokenAddress: string; spender: string }; claim?: { recipient: string } }
) => void {
  const { chainId, account } = useActiveWeb3React()
  const { addTransaction } = useAppConext()

  return useCallback(
    (
      response: TransactionResponse,
      {
        summary,
        approval,
        claim,
      }: { summary?: string; claim?: { recipient: string }; approval?: { tokenAddress: string; spender: string } } = {}
    ) => {
      if (!account) return
      if (!chainId) return

      const { hash } = response
      if (!hash) {
        throw Error('No transaction hash found.')
      }

      addTransaction(chainId, hash, account, approval, claim, summary)
    },
    [account, chainId, addTransaction]
  )
}
