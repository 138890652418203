import Logo from 'components/Logo'
import { useActiveWeb3React } from 'hooks'
import { useRef } from 'react'
import styled from 'styled-components/macro'
import { MEDIA_WIDTHS } from 'theme'
import { CHAIN_INFO, ChainId } from '../../constants'

const NetworkLabel = styled.div`
  flex: 1 1 auto;
`
const SelectorLabel = styled(NetworkLabel)`
  display: none;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    display: block;
    margin-right: 8px;
  }
`
const SelectorControls = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.bg1};
  border: 2px solid ${({ theme }) => theme.bg1};
  border-radius: 12px;
  color: ${({ theme }) => theme.text1};
  cursor: auto;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding: 6px 8px;
`
const SelectorLogo = styled(Logo)`
  width: 24px;
  margin-right: 0px;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    margin-right: 8px;
  }
`
const SelectorWrapper = styled.div`
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    position: relative;
  }
`

export default function NetworkSelector(): JSX.Element | null {
  const { chainId, library } = useActiveWeb3React()
  const node = useRef<HTMLDivElement>()

  const info = chainId ? CHAIN_INFO[chainId] : undefined
  const mainnetInfo = CHAIN_INFO[ChainId.MAINNET]

  if (!chainId || !info || !library) {
    return null
  }

  return (
    <SelectorWrapper ref={node as any}>
      <SelectorControls>
        <SelectorLogo src={info.logoUrl || mainnetInfo.logoUrl} />
        <SelectorLabel>{info.label}</SelectorLabel>
      </SelectorControls>
    </SelectorWrapper>
  )
}
