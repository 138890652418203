/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useReducer, ReactNode, useCallback, useEffect } from 'react'
import { useActiveWeb3React } from '../hooks'
import useIsWindowVisible from '../hooks/useIsWindowVisible'
import useDebounce from '../hooks/useDebounce'
// import useInterval from '../hooks/useInterval'
import { ChainId } from '../constants'
import { Token } from '../tokens'

export interface AppActionPayload {
  chainId?: ChainId
  hash?: string
  transaction?: TransactionDetails
  blockNumber?: number
}

export interface AppAction {
  type: string
  payload?: AppActionPayload
}

export interface Props {
  children: ReactNode
}
export interface SerializableTransactionReceipt {
  to: string
  from: string
  contractAddress: string
  transactionIndex: number
  blockHash: string
  transactionHash: string
  blockNumber: number
  status?: number
}

export interface TransactionDetails {
  hash: string
  approval?: { tokenAddress: string; spender: string }
  summary?: string
  claim?: { recipient: string }
  receipt?: SerializableTransactionReceipt
  lastCheckedBlockNumber?: number
  addedTime: number
  confirmedTime?: number
  from: string
}

export interface TransactionState {
  [chainId: number]: {
    [txHash: string]: TransactionDetails
  }
}

export interface TokenState {
  [chainId: number]: {
    [address: string]: Token
  }
}

export interface AppState {
  walletModalOpen: boolean
  blockNumber: number
  transactions: TransactionState
}

const initialState: AppState = {
  walletModalOpen: false,
  blockNumber: 0,
  transactions: {},
}

const OPEN_WALLET_MODAL = 'OPEN_WALLET_MODAL'
const CLOSE_WALLET_MODAL = 'CLOSE_WALLET_MODAL'
const CLEAR_ALL_TRANSACTIONS = 'CLEAR_ALL_TRANSACTIONS'
const ADD_TRANSACTION = 'ADD_TRANSACTION'
const FINALIZE_TRANSACTION = 'FINALIZE_TRANSACTION'
const CHECKED_TRANSACTION = 'CHECKED_TRANSACTION'
const UPDATE_BLOCK_NUMBER = 'UPDATE_BLOCK_NUMBER'

const reducer = (state: AppState, action: AppAction) => {
  switch (action.type) {
    case OPEN_WALLET_MODAL: {
      return {
        ...state,
        walletModalOpen: true,
      }
    }
    case CLOSE_WALLET_MODAL: {
      return {
        ...state,
        walletModalOpen: false,
      }
    }
    case CLEAR_ALL_TRANSACTIONS: {
      const newTransactions = { ...state.transactions }
      const { chainId } = action?.payload ?? {}

      if (chainId) {
        newTransactions[chainId] = {}
      }

      return {
        ...state,
        transactions: newTransactions,
      }
    }
    case FINALIZE_TRANSACTION:
    case CHECKED_TRANSACTION:
    case ADD_TRANSACTION: {
      const { chainId, hash, transaction } = action?.payload ?? {}
      const newTransactions = { ...state.transactions }

      if (chainId && hash && transaction) {
        const txs = newTransactions[chainId] ?? {}
        txs[hash] = transaction
        newTransactions[chainId] = txs
      }

      return {
        ...state,
        transactions: newTransactions,
      }
    }
    case UPDATE_BLOCK_NUMBER: {
      const { blockNumber } = action?.payload ?? {}

      if (blockNumber) {
        return {
          ...state,
          blockNumber,
        }
      }

      return { ...state }
    }
    default: {
      return { ...state }
    }
  }
}

const AppContext = createContext({
  ...initialState,
  toggleWalletModal: (): void => {},
  isTransactionRecent: (tx: TransactionDetails): boolean => false,
  clearAllTransactions: (chainId: ChainId): void => {},
  addTransaction: (
    chainId: ChainId,
    hash: string,
    from: string,
    approval?: { tokenAddress: string; spender: string },
    claim?: { recipient: string },
    summary?: string
  ): void => {},
  finalizeTransaction: (chainId: ChainId, hash: string, receipt: SerializableTransactionReceipt): void => {},
  checkedTransaction: (chainId: ChainId, hash: string, blockNumber: number): void => {},
})

export const AppProvider = ({ children }: Props): JSX.Element => {
  const { library, chainId } = useActiveWeb3React()
  const windowVisible = useIsWindowVisible()
  const [state, dispatch] = useReducer(reducer, initialState)

  const toggleWalletModal = () => {
    if (state.walletModalOpen) {
      dispatch({ type: CLOSE_WALLET_MODAL })
    } else {
      dispatch({ type: OPEN_WALLET_MODAL })
    }
  }

  const isTransactionRecent = (tx: TransactionDetails): boolean => {
    return new Date().getTime() - tx.addedTime < 86_400_000
  }

  const clearAllTransactions = (chainId: ChainId): void => {
    dispatch({
      type: CLEAR_ALL_TRANSACTIONS,
      payload: {
        chainId,
      },
    })
  }

  const addTransaction = (
    chainId: ChainId,
    hash: string,
    from: string,
    approval?: { tokenAddress: string; spender: string },
    claim?: { recipient: string },
    summary?: string
  ) => {
    if (state.transactions[chainId]?.[hash]) {
      throw Error('Attempted to add existing transaction.')
    }

    const transaction = { hash, approval, summary, claim, from, addedTime: new Date().getTime() }

    dispatch({
      type: ADD_TRANSACTION,
      payload: {
        hash,
        chainId,
        transaction,
      },
    })
  }
  const finalizeTransaction = (chainId: ChainId, hash: string, receipt: SerializableTransactionReceipt) => {
    const transaction = state.transactions[chainId]?.[hash]
    if (!transaction) {
      return
    }
    transaction.receipt = receipt
    transaction.confirmedTime = new Date().getTime()

    dispatch({
      type: FINALIZE_TRANSACTION,
      payload: {
        hash,
        chainId,
        transaction,
      },
    })
  }

  const checkedTransaction = (chainId: ChainId, hash: string, blockNumber: number) => {
    const transaction = state.transactions[chainId]?.[hash]

    if (!transaction) {
      return
    }

    if (!transaction.lastCheckedBlockNumber) {
      transaction.lastCheckedBlockNumber = blockNumber
    } else {
      transaction.lastCheckedBlockNumber = Math.max(blockNumber, transaction.lastCheckedBlockNumber)
    }

    dispatch({
      type: CHECKED_TRANSACTION,
      payload: {
        hash,
        chainId,
        transaction,
      },
    })
  }

  const blockNumberCallback = useCallback(
    (blockNumber: number) => {
      if (blockNumber !== state.blockNumber) {
        dispatch({
          type: UPDATE_BLOCK_NUMBER,
          payload: { blockNumber },
        })
      }
    },
    [state.blockNumber]
  )

  useEffect(() => {
    if (!library || !chainId || !windowVisible) return undefined

    library
      .getBlockNumber()
      .then(blockNumberCallback)
      .catch((error) => console.error(`Failed to get block number for chainId: ${chainId}`, error))

    library.on('block', blockNumberCallback)

    return () => {
      library.removeListener('block', blockNumberCallback)
    }
  }, [chainId, library, blockNumberCallback, windowVisible])

  const debouncedState = useDebounce(state, 400)

  useEffect(() => {
    if (!chainId || !debouncedState.blockNumber || !windowVisible) return
    dispatch({
      type: UPDATE_BLOCK_NUMBER,
      payload: { blockNumber: debouncedState.blockNumber },
    })
  }, [windowVisible, dispatch, debouncedState.blockNumber, chainId])

  return (
    <AppContext.Provider
      value={{
        ...state,
        toggleWalletModal,
        isTransactionRecent,
        clearAllTransactions,
        addTransaction,
        finalizeTransaction,
        checkedTransaction,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContext
