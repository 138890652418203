import { useMemo } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { useActiveWeb3React } from '.'
import { useRewardsContract } from './useContract'
import { ETHEREUM_CONTRACT } from '../constants'
import { useTransactionAdder } from './useTransactionAdder'
import { calculateGasMargin } from '../utils'

export enum CallbackState {
  INVALID,
  LOADING,
  VALID,
}

export function useRewardsCallback(): {
  state: CallbackState
  callback: null | (() => Promise<string>)
  error: string | null
} {
  const { account, chainId, library } = useActiveWeb3React()
  const contract = useRewardsContract(ETHEREUM_CONTRACT)
  const addTransaction = useTransactionAdder()
  const methodName = 'withdraw'

  return useMemo(() => {
    if (!library || !account || !chainId || !ETHEREUM_CONTRACT) {
      return {
        state: CallbackState.INVALID,
        callback: null,
        nativeCallback: null,
        error: 'Missing dependencies',
      }
    }

    if (!contract) {
      return { state: CallbackState.INVALID, callback: null, nativeCallback: null, error: 'Invalid contract' }
    }

    return {
      state: CallbackState.VALID,
      callback: async function onClaim(): Promise<string> {
        const args: any = []
        let gasEstimate: BigNumber

        try {
          gasEstimate = await contract.estimateGas[methodName](...args)
        } catch (gasError) {
          try {
            const errorResult = await contract.callStatic[methodName](...args)

            console.debug('Unexpected successful call after failed estimate gas', gasError, errorResult)
          } catch (callStaticError) {
            console.error(callStaticError)
          }

          throw new Error('Unexpected issue with estimating the gas. Please try again.')
        }

        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(gasEstimate),
          from: account,
        })
          .then((response: any) => {
            addTransaction(response, {
              summary: `Claimed $QUARTZ vested rewards.`,
            })

            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === 4001) {
              throw new Error('Transaction rejected.')
            } else {
              // otherwise, the error was unexpected and we need to convey that
              console.error(`Claim failed`, error, methodName, args)
              throw new Error(`Claim failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [library, account, chainId, contract, addTransaction])
}
