import { HelpCircle } from 'react-feather'
import { ImageProps } from 'rebass'

export interface LogoProps extends Pick<ImageProps, 'style' | 'alt' | 'className'> {
  src?: string
}

/**
 * Renders an image by sequentially trying a list of URIs, and then eventually a fallback triangle alert
 */
export default function Logo({ src, alt, ...rest }: LogoProps): JSX.Element {
  if (src) {
    return <img {...rest} alt={alt} src={src} />
  }

  return <HelpCircle {...rest} />
}
