import { useState, useEffect } from 'react'
import { formatUnits } from '@ethersproject/units'
import { useActiveWeb3React } from '.'
import { usePhaseOneContract } from './useContract'
import { ChainId, POLYGON_CONTRACT } from '../constants'
import { useAppConext } from './useAppConext'

export enum CallbackState {
  INVALID,
  LOADING,
  VALID,
}

export function useCurrentlyClaimable(): number {
  const [available, setAvailable] = useState<number>(0)
  const { account, chainId, library } = useActiveWeb3React()
  const { blockNumber } = useAppConext()
  const contract = usePhaseOneContract(POLYGON_CONTRACT)
  const methodName = 'currentlyClaimable'

  useEffect(() => {
    let cancelled = false
    const fetch = async () => {
      if (!blockNumber || !library || !contract || !account || !chainId || !POLYGON_CONTRACT) {
        return
      }

      if (chainId !== ChainId.POLYGON_MAINNET && chainId !== ChainId.POLYGON_TESTNET) {
        return
      }

      try {
        const response = await contract[methodName](account)

        if (!cancelled && response) {
          setAvailable(parseFloat(formatUnits(response, 18)))
        }
      } catch (error) {
        console.error('Error trying to get phase I claimable amount: ', error)
      }

      return
    }

    fetch()

    return (): void => {
      cancelled = true
    }
  }, [account, chainId, contract, library, blockNumber])

  return available
}
