import styled, { ThemeContext } from 'styled-components'
import { TransactionReceipt } from '@ethersproject/providers'
import { Text } from 'rebass'
import { useContext, useState, useEffect, useCallback } from 'react'
import { transparentize } from 'polished'
import { AlertTriangle } from 'react-feather'
import AppBody from 'AppBody'
import { AutoColumn } from 'components/Column'
import { RowCenter, AutoRow } from './components/Row'
import { ButtonError, ButtonGreenPrimary } from './components/Button'
import { useActiveWeb3React } from 'hooks'
import { useAppConext } from 'hooks/useAppConext'
import { ChainId } from './constants'
import { switchToNetwork } from 'utils/switchToNetwork'
import { useClaimCallback } from 'hooks/useClaimCallback'
import { useCurrentlyClaimable } from 'hooks/useCurrentlyClaimable'
import { useChaintransactions } from 'hooks/useChaintransactions'
import { shouldCheck } from 'utils/shouldCheck'

const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
`
const ActiveText = styled.div`
  font-weight: 500;
  font-size: 20px;
`
export const Wrapper = styled.div`
  position: relative;
  padding: 1rem;
`

export const BottomGrouping = styled.div`
  margin-top: 1rem;
`

const CallbackErrorInner = styled.div`
  background-color: ${({ theme }) => transparentize(0.9, theme.red1)};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.825rem;
  width: 100%;
  padding: 3rem 1.25rem 1rem 1rem;
  margin-top: -2rem;
  color: ${({ theme }) => theme.red1};
  z-index: -1;
  p {
    padding: 0;
    margin: 0;
    font-weight: 500;
  }
`

const CallbackErrorInnerAlertTriangle = styled.div`
  background-color: ${({ theme }) => transparentize(0.9, theme.red1)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 12px;
  min-width: 48px;
  height: 48px;
`

export function CallbackError({ error }: { error: string }): JSX.Element {
  return (
    <CallbackErrorInner>
      <CallbackErrorInnerAlertTriangle>
        <AlertTriangle size={24} />
      </CallbackErrorInnerAlertTriangle>
      <p>{error}</p>
    </CallbackErrorInner>
  )
}

export default function Phase1(): JSX.Element {
  const { account, library, chainId } = useActiveWeb3React()
  const { blockNumber, toggleWalletModal, finalizeTransaction, checkedTransaction } = useAppConext()
  const theme = useContext(ThemeContext)
  const chaintransactions = useChaintransactions()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const { callback: claimCallback } = useClaimCallback()
  const available = useCurrentlyClaimable()

  const handleClaim = useCallback(() => {
    if (!claimCallback) {
      return
    }

    setErrorMessage(undefined)

    claimCallback()
      .then(() => {
        setErrorMessage(undefined)
      })
      .catch((error) => {
        setErrorMessage(error.message)
      })
  }, [claimCallback])

  useEffect(() => {
    if (!chainId || !library || !blockNumber) return

    Object.keys(chaintransactions)
      .filter((hash) => shouldCheck(blockNumber, chaintransactions[hash]))
      .forEach((hash) => {
        library
          .getTransactionReceipt(hash)
          .then((receipt: TransactionReceipt) => {
            if (receipt) {
              finalizeTransaction(chainId, hash, {
                blockHash: receipt.blockHash,
                blockNumber: receipt.blockNumber,
                contractAddress: receipt.contractAddress,
                from: receipt.from,
                status: receipt.status,
                to: receipt.to,
                transactionHash: receipt.transactionHash,
                transactionIndex: receipt.transactionIndex,
              })
            } else {
              checkedTransaction(chainId, hash, blockNumber)
            }
          })
          .catch((error) => {
            console.error(`failed to check transaction hash: ${hash}`, error)
          })
      })
  }, [chainId, library, blockNumber, chaintransactions, finalizeTransaction, checkedTransaction])

  useEffect(() => {
    if (!library || !chainId || chainId === ChainId.POLYGON_MAINNET) {
      return
    }

    switchToNetwork({ library, chainId: ChainId.POLYGON_MAINNET })
  }, [chainId, library])

  return (
    <>
      <AppBody>
        <Tabs>
          <RowCenter style={{ padding: '1rem 1rem 0 1rem' }}>
            <ActiveText>Phase I</ActiveText>
          </RowCenter>
        </Tabs>
        <Wrapper>
          <AutoColumn gap="md">
            <AutoRow justify="space-around" gap="4px">
              <AutoColumn justify="center">
                <Text fontWeight={500} fontSize={14} color={theme.text2} pt={1}>
                  Available: {available} QUARTZ
                </Text>
              </AutoColumn>
            </AutoRow>
          </AutoColumn>
          <BottomGrouping>
            {!account && <ButtonGreenPrimary onClick={toggleWalletModal}>Connect Wallet</ButtonGreenPrimary>}
            {account && (
              <ButtonError
                onClick={() => handleClaim()}
                id="claim-button"
                disabled={!!errorMessage || available <= 0}
                error={!!errorMessage}
              >
                <Text fontSize={20} fontWeight={500}>
                  Claim
                </Text>
              </ButtonError>
            )}
            {errorMessage ? <CallbackError error={errorMessage} /> : null}
          </BottomGrouping>
        </Wrapper>
      </AppBody>
    </>
  )
}
